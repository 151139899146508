import type { ErrorResponse } from '@remix-run/react';
import { Link, isRouteErrorResponse } from '@remix-run/react';
import { ExternalLink } from 'lucide-react';
import { cn } from '~/utils/cn';
import type { ActionEventDataError } from '~/utils/response';
import { splitCamelcase } from '~/utils/split-camelcase';
import { Button, buttonVariants } from './Button';
import { Card, CardContent } from './Card';
import { Logo } from './Logo';

const isError = (error: unknown): error is Error => error instanceof Error;
const isActionEventDataError = (data: any): data is ActionEventDataError =>
  data?.__data?.type === 'error';

interface Props {
  error: ErrorResponse | Error | any;
}

export function ServerError({ error }: Props) {
  let title = 'Error';
  let detail: string | null = typeof error === 'string' ? error : null;
  let sentryLink: string | null = null;

  console.error(error);

  if (isError(error)) {
    title = splitCamelcase(error.name);
    detail = error.message;
  } else if (isRouteErrorResponse(error)) {
    if (isActionEventDataError(error.data)) {
      title = splitCamelcase(error.data.__data.error.name.replace(/Error/i, ''));
      detail = error.data.__data.error.message;
      sentryLink = `https://colette.sentry.io/issues/?project=6363659&query=id%3A${error.data.__data.id}`;
    } else {
      title = error.statusText;
      detail = typeof error.data === 'string' ? error.data : JSON.stringify(error.data);
    }
  }

  return (
    <main className="w-screen h-screen flex items-center justify-center overflow-hidden px-12">
      <div className="absolute inset-y-0 left-0 z-50 w-1/12 bg-linear-to-r from-white from-15% to-transparent md:w-1/4" />
      <div className="absolute inset-y-0 right-0 z-50 w-1/12 bg-linear-to-l from-white from-15% to-transparent md:w-1/4" />
      <div className="absolute inset-x-0 top-0 z-50 h-1/6 bg-linear-to-t from-transparent from-15% to-white" />
      <div className="absolute inset-x-0 bottom-0 z-50 h-1/6 bg-linear-to-b from-transparent from-15% to-white" />

      <div className="grid grid-flow-row auto-rows-[minmax(0,_48px)] grid-cols-[repeat(12,minmax(0,48px))]">
        <div className="relative col-span-2 col-end-13 row-span-1 row-start-1 md:row-span-2">
          <Logo className="relative z-10 aspect-square size-full [&_path]:origin-center [&_path]:animate-grow [&_path]:fill-white [&_path]:stroke-white [&_path]:stroke-4 [&_path]:delay-0" />
          <Line className="right-0" direction="vertical" />
          <Line className="top-0" direction="horizontal" />
          <Line className="bottom-0" direction="horizontal" />
        </div>

        <div className="relative col-span-10 col-start-1 row-span-2 row-start-2 md:row-span-1 md:row-start-3">
          <h1
            className="relative z-10 -ml-[3px] -mt-2 block p-0 text-[clamp(3.5rem,_5vw,_3rem)] font-semibold leading-none md:-ml-1.5 md:mt-0 md:text-[clamp(4.25rem,_5vw,_3rem)] md:leading-[0.7]"
            style={{
              letterSpacing: 'clamp(-7px,calc(-1px - 1vw),-4.6px)',
            }}
          >
            {title}
          </h1>
          <Line direction="vertical" className="left-0" />
          <Line
            direction="vertical"
            className="right-0 bg-transparent"
            style={{
              backgroundImage:
                'linear-gradient(to bottom, var(--color-border) 1px, transparent 1px)',
              backgroundSize: '1px 2px',
            }}
          />
          <Line
            direction="horizontal"
            className="bottom-0 bg-transparent"
            style={{
              backgroundImage:
                'linear-gradient(to right, var(--color-border) 1px, transparent 1px)',
              backgroundSize: '2px 1px',
            }}
          />
        </div>

        <div className="relative col-span-full col-start-1 row-start-5 row-end-13 flex flex-col">
          <Card className="flex-1 rounded-[48px] shadow-none">
            <Line className="top-0" direction="horizontal" />
            <Line className="bottom-0" direction="horizontal" />

            <CardContent className="flex h-full flex-col justify-center gap-4 pt-6">
              <p className="line-clamp-4 text-center font-medium">{detail}</p>

              <div className="flex items-center justify-center gap-4 pt-2">
                <Button variant="outline" size="lg" onClick={() => window.location.reload()}>
                  Refresh
                </Button>

                {sentryLink ? (
                  <Link
                    className={buttonVariants({ variant: 'default', size: 'lg' })}
                    to={sentryLink}
                    target="_blank"
                  >
                    Sentry <ExternalLink className="ml-2 size-4" />
                  </Link>
                ) : null}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </main>
  );
}

export function Line({
  direction,
  ...props
}: React.ComponentPropsWithRef<'div'> & { direction: 'horizontal' | 'vertical' }) {
  return (
    <div
      {...props}
      className={cn(
        "absolute z-0 bg-border content-['']",
        direction === 'horizontal'
          ? 'inset-x-full -left-[200vw] h-px w-[400vw]'
          : 'inset-y-full -top-[200vh] h-[400vh] w-px',
        props.className,
      )}
    />
  );
}
